import React,  { useState }from 'react';
import './card-counter.css';

const CardCounter = ({ cards, containerClass }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null); // Keep track of the hovered card index

  return (
    <div className={containerClass}>
      {cards.map((card, index) => (
        <div
          key={index}
          className="card-container"
          onMouseEnter={() => setHoveredCardIndex(index)}
          onMouseLeave={() => setHoveredCardIndex(null)}
        >
          {hoveredCardIndex === index ? (
            <div className="population-card"  
              style={{
                border: `2px solid ${card.titleColour}`,
                height: "100%"
              }}
            >
              <h3
                style={{
                  backgroundColor: card.titleColour,
                  transition: 'opacity 0.5s ease-in-out',
                }}
              >
                {card.title}
              </h3>
              <p>{card.text}</p>
            </div>
          ) : (
            <div className="population-card">
              <img
                src={card.src}
                alt={card.alt}
                style={{
                  transition: 'opacity 0.5s ease-in-out',
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CardCounter;