import "./App.css";
import React, { useState, useEffect } from "react";

import BurgerMenu from "./components/menu-burger";
import Cards from "./components/card-counter";
import TeamCard from "./components/team-card";
import List from "./components/list";
import SkipToMainContent from "./components/skip-to-main-content";

import ig from "./assets/icons/coloured-ig.png";
import email from "./assets/icons/round-email.png";
import mobileCover from "./assets/visuals/mobileCover.png";
import webCover from "./assets/visuals/webCover.png";
import mobileDividerCover from "./assets/visuals/mobileDividerCover.png";
import webDividerCover from "./assets/visuals/dividerCover.png";
import elements from "./assets/visuals/elementsWeb.png";
import mission from "./assets/visuals/missionCover.png";
import howToPlayImg from "./assets/visuals/howToPlay.jpg";
import rulebookImg from "./assets/rulebook.png";
import glossaryImg from "./assets/glossary-img.png";
import educatorsManualImg from "./assets/manual-img.png";

import teresa from "./assets/teresa-card.jpeg";
import javier from "./assets/javi-card.jpeg";
import vicky from "./assets/vicky-card.jpeg";
import whitney from "./assets/whitney-card.jpeg";
import raymi from "./assets/raymi-card.jpeg";
import teresaHovered from "./assets/cards-old/teresa-card.png";
import javierHovered from "./assets/cards-old/javier-card.png";
import vickyHovered from "./assets/cards-old/vicky-card.png";
import whitneyHovered from "./assets/cards-old/whitney-card.png";
import raymiHovered from "./assets/cards-old/raymi-card.png";

import athletes from "./assets/population-target/athletes.png";
import teachers from "./assets/population-target/teachers-coaches.png";
import families from "./assets/population-target/families.png";
import stakeholders from "./assets/population-target/stakeholders.png";
import youngAthletes from "./assets/population-target/young-athletes.png";
import students from "./assets/population-target/students.png";

// import black from "./assets/cards/black.png";
import grey from "./assets/cards/grey.png";
import brown from "./assets/cards/brown.png";
// import yellow from "./assets/cards/yellow.png";
// import purple from "./assets/cards/purple.png";
import green from "./assets/cards/green.png";

import drawing1 from "./assets/pngs/20.png";
import drawing2 from "./assets/pngs/27.png";

function App() {
  window.addEventListener("load", function () {
    // Check if the URL contains a hash value
    if (window.location.hash) {
      // Scroll to the section with the matching ID
      let targetElement = document.querySelector(window.location.hash);
      if (targetElement) {
        targetElement.scrollIntoView();
      }
    }
  });

  const cards = [
    {
      src: athletes,
      alt: "Elite and amateur senior athletes",
      title: "ATHLETES",
      titleColour: "#000",
      text: "Connect with fellow coaches, staff, and athletes while gaining valuable insights to ensure secure and inclusive environments for both your athletes and peers.",
    },
    {
      src: teachers,
      alt: "Teachers and coaches working with youth",
      title: "TEACHERS and COACHES",
      titleColour: "#EA3955",
      text: "You can play with your students or simply with your fellow teachers. You can use this game to learn about and teach real life problems through sport. Any sessions involving PE, ethics, health, philosophy, inclusion or law would be a perfect occasion!",
    },
    {
      src: families,
      alt: "Families and groups of friends looking for a fun activity",
      title: "FAMILIES & FANS",
      titleColour: "#B7C7D4",
      text: "You like sports or have a family member involved in sports? This game is also for you!" ,
    },
    {
      src: youngAthletes,
      alt: "Young athletes and students aged 13 to 18",
      title: "YOUNG ATHLETES AND STUDENTS",
      titleColour: "#B7C7D4",
      text: "You will enjoy a fun session in the classroom and debate with your classmates or teammates. You will learn about some issues that can affect you whether you play sports or not. Any sessions involving PE, ethics, health, philosophy, inclusion or law would be a perfect occasion!",

    },
    {
      src: stakeholders,
      alt: "Decision makers and sport stakeholders and staff",
      title: "SPORTS GOVERNING BODIES",
      titleColour: "#0D954E",
      text: "Perfect tool for your sports organization to train your staff and have a fun session. They can use the knowledge they take from playing this game to raise awareness and take steps towards good governance inside your organization and within your sport.",
    },
    {
      src: students,
      alt: "Higher education students and teachers",
      title: "HIGHER EDUCATION STUDENTS AND TEACHERS",
      titleColour: "#F6AC33",
      text: "You will enjoy a fun session in the classroom and debate with your classmates. You will learn about some issues that can affect you whether you play sports or not. Any sessions involving PE, ethics, health, philosophy, inclusion or law would be a perfect occasion!",
    }
  ]

  const items = [
    {
      tag: "h3",
      text: "What is FIX IT! ?",
      sibling: `<p><strong>FIX IT!</strong> is an innovative and educational card game promoting vital dialogue and debate about sport integrity issues and values. The game addresses common <span class="text-emphasis">integrity issues in both sports and society,</span> offering users a simple way to learn about prevention and solutions for real-life challenges like safeguarding, discrimination, various forms of abuse, and doping.<br><br>After more than 400 volunteer testers played FIX IT!, they reported learning and having fresh insights into the topics, yet stated they still lacked confidence in reporting or seeking support for the issues covered. Consequently, FIX IT! evolved from a card game into a <span class="text-emphasis">comprehensive project</span> that became also a website featuring resources, definitions, and useful links, an educator's manual, and a cause, where proceeds from games sold support important sport non profit organizations around the world.</p>`,
    },
  ];
  const missionItems = [
    {
      tag: "h3",
      text: "Mission",
      sibling: `<p>Empower sport stakeholders to <span class="text-emphasis">support safer, inclusive and more accessible sport spaces</span> by enhancing awareness, attitudes and approaches to solve and prevent ethical challenges in sport and life.</p>`,
    },
  ];
  const whoIsFixitForItems = [
    {
      tag: "h3",
      text: "Who is FIX IT! for?",
      sibling: `<p><strong>FIX IT!</strong> was crafted for participants aged 13 and above, and is suitable for diverse settings such as family nights or educational sessions with various sports stakeholders. Engage in the FIX IT! experience to learn and discuss a range of sport and social issues while enjoying the thrill of a competitive card game. It's an entertaining way to expand your knowledge and spark <span class="text-emphasis">meaningful conversations!</span></p>`,
    },
  ];
  const footerItems = [
    {
      sibling: `<a href="https://www.instagram.com/fixitthegame" target="_blank"><img alt="Go to Fix it the game Instagram" src=${ig}></a>`,
    },
    {
      sibling: `<a href="mailto:hello@fixitthegame.com"><img alt="Email Fix it the game" src=${email}></a>`,
    },
    {
      sibling: `<p>© Copyright 2023. <br/>All rights reserved.</p>`,
    },
  ];
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const downloadGlossary = () => {
    const pdfPath = "./pdfs/Glossary.pdf";
    window.open(pdfPath, "_blank");
  };
  const downloadManual = () => {
    const pdfPath = "./pdfs/EducatorsManual.pdf";
    window.open(pdfPath, "_blank");
  };

  const downloadRules = () => {
    const pdfPath = "./pdfs/RuleBook.pdf";
    window.open(pdfPath, "_blank");
  };
  const howToPlay = () => {
    const pdfPath = "./pdfs/HowToPlay.pdf";
    window.open(pdfPath, "_blank");
  };

  return (
    <div className="App">
      <SkipToMainContent />
      <header className="App-header">
        <BurgerMenu />
      </header>
      <main id="main-content">
        {isMobile ? (
          <img className="mobile-cover-img" src={mobileCover} alt="" />
        ) : (
          <>
            <img className="web-cover-img" src={webCover} alt="" />
            <h1>The Sports Integrity and Values Game</h1>
          </>
        )}
        <section id="about-section">
          <h2 id="about-heading">About</h2>
          <List containerClass="small-padding" items={items} />
          <div className="mobile-divider-cover">
            <img
              src={elements}
              alt=""
              className="mobile-divider-cover-img"
              id="elements-slider"
            />
          </div>
          <List containerClass="small-padding" items={missionItems} />
          <div className="mobile-divider-cover">
            <h4 className="mission-heading">The aim of FIX IT! is to:</h4>
            <div className="drawing-container">
              <img src={drawing1} alt="" className="drawing" />
              <img src={mission} alt="" className="mission-img" />
              <img src={drawing2} alt="" className="drawing" />
            </div>
          </div>
          {/* <div className="divider-two">
          <img alt="" src={yellow} />
          <img alt="" src={purple} />
        </div> */}
          <List containerClass="small-padding" items={whoIsFixitForItems} />
          <p>
            <strong>
              Do you see <span className="text-emphasis">yourself</span> in the
              list below? Then FIX IT! is there for you!
            </strong>
          </p>
          <Cards cards={cards} containerClass={"target-audiences"} />
          <iframe
            className="video"
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/IhkQLoM3Rnw?si=T2jdA59mKB6q9qLc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </section>

        <section id="how-to-play-section">
          <h2 id="how-to-play-heading">How to play</h2>
          <p className="text-important">Game on!</p>
          <p>
            To play, players draw cards aiming to collect as many medals as
            possible to win. At the{" "}
            <span className="text-emphasis">end of the season</span> (when all
            cards are drawn and players have no more cards to play), the player
            who has collected the most medals wins the game.
          </p>
          <p>
            While trying to earn medals, players face various{" "}
            <span className="text-emphasis">opportunities</span> to support
            safer, more ethical and inclusive sport: strengthening awareness,
            allyship, age-appropriate response, sportsmanship and sporting
            values.
          </p>
          <p>
            However, players will also experience
            <span className="text-emphasis">
              {" "}
              ethical issues and barriers
            </span>{" "}
            to sport as individuals draw and play cards that introduce players
            to diverse sporting risks that people face in the real world. These
            <span className="text-emphasis"> sport integrity issues</span> will
            have to be fixed by drawing cards that allow players to either:
            report the problem; or by drawing cards that supports their improved
            participation by “unlocking”: an opportunity to report, a new
            preventative policy, improved response and sanctions, or by
            increased awareness and support systems around the issue they are
            facing.
          </p>
          <p>
            In both sport and society, athletes can face difficult issues which
            impact their opportunities, performance, health, wellbeing, and
            their lives. Just like in the real world, players of FIX IT! will
            not be able to participate or collect medals if they are facing a
            sport integrity problem. Likewise, when players see{" "}
            <span className="text-emphasis">barriers to sport removed</span> and
            feel supported by their peers and organizations- they can hop, skip,
            jump, skate and leap to the podium with ease!
          </p>
          <p>
            You can find the rules{" "}
            <button id="rule-download" onClick={downloadRules}>
              here
            </button>
            {". "}
            Soon you will be able to watch the video version explaining the
            rules.
          </p>
          <div className="rulebook-howtoplay-container">
            <img
              src={rulebookImg}
              className="how-to-play-img rulebook-img"
              alt="Download the rules here"
              onClick={downloadRules}
            />
            <img src={howToPlayImg} className="how-to-play-img" alt="" onClick={howToPlay} />
          </div>
        </section>
        <div className="mobile-divider-cover drawings-cover">
          {isMobile ? (
            <img
              src={mobileDividerCover}
              alt=""
              className="mobile-divider-cover-img"
            />
          ) : (
            <img
              src={webDividerCover}
              alt=""
              className="mobile-divider-cover-img"
            />
          )}
        </div>
        <section id="resources-section">
          <h2 id="resources-heading">Resources</h2>
          <p className="text-important">Want to learn more?</p>
          <p>
            In this section you can find resources that will help you access
            additional information about these topics, but also find support for
            yourself or someone you may know who may be facing one of the issues
            covered in the game.
          </p>
          <p>The resources are divided in two:</p>
          <ol>
            <li>
              A <span className="text-emphasis">glossary of terms</span>, which
              includes additional links, websites and organisations for each
              definition, where you can access more materials, courses and
              report problems with the specific organisations.
            </li>
            <li>
              <span className="text-emphasis">Educators manual</span>, which
              includes the FIX IT! for educators and coaches manual with
              tailored activities for your students, athletes or staff. You can
              use these activities after playing FIX IT!
            </li>
          </ol>
          <div className="resources-button-container">
            <button
              className="resources-button glossary-button"
              onClick={downloadGlossary}
            >
              <img
                src={glossaryImg}
                className="glossary-img"
                alt="click to download the glossary"
              />
            </button>

            <button
              className="resources-button educators-button"
              onClick={downloadManual}
            >
              <img
                src={educatorsManualImg}
                className="educators-manual-img"
                alt="click to download the Educators Manual"
              />
            </button>
          </div>
        </section>
        <section id="buy-fix-it-section">
          <h2 id="buy-fix-it-heading">Buy FIX IT!</h2>
          <p className="text-important">You can now buy FIX IT!</p>
          <p>
            FIX IT! is now available for purchase in English and Spanish{" "}
            <a
              className="text-emphasis"
              target="_blank"
              rel="noreferrer"
              href="http://www.fixittheshop.com/shop"
            >
              here.
            </a>
          </p>
          <p>
            If you are buying FIX IT! for your{" "}
            <strong>school, club, federation</strong> or for an{" "}
            <strong>event</strong>, and would like to buy more than 20 games,
            contact
            <span className="text-emphasis"> hello@fixitthegame.com</span>
          </p>

          <p>
            If you would like to organise a workshop at an event, contact our
            team at{" "}
            <span className="text-emphasis">hello@fixitthegame.com</span>
          </p>
          <p>
            We are currently working to make FIX IT! available in more
            languages!
          </p>
        </section>
        <div className="divider-three-container">
          <div className="divider-three">
            <img alt="" src={grey} />
            <img alt="" src={green} />
            <img alt="" src={brown} />
          </div>
        </div>
        <section id="meet-the-team-section">
          <h2 id="meet-the-team-heading">Meet the team</h2>
          <p className="text-important">Who is behind FIX IT! ?</p>
          <p>
            <strong>FIX IT!</strong> was born out of the passion of its team
            members to educate and create safe spaces across the globe.{" "}
            <span className="text-emphasis">Created by sports ethicists,</span>{" "}
            the cards have been carefully drafted to showcase diversity and
            cover highly relevant topics for all. FIX IT! is the result of
            converting the content of a MA in Sports Ethics and Integrity into a
            unique, innovative, fun and effective educational tool:
          </p>
          <div className="team-container">
            <TeamCard source={vicky} hoverSource={vickyHovered} />
            <TeamCard source={whitney} hoverSource={whitneyHovered} />
            <TeamCard source={teresa} hoverSource={teresaHovered} />
            <TeamCard source={javier} hoverSource={javierHovered} />
            <TeamCard source={raymi} hoverSource={raymiHovered} />
            {isMobile && (
              <TeamCard
                text={
                  "FIX IT! would like to thank all the volunteers who tested and helped shape the smallest details of the game. Special thanks to the MA in Sports Ethics and Integrity students and alumni."
                }
              />
            )}
          </div>
          {!isMobile && (
            <div className="thanks-p">
              <p className="text-emphasis">
                FIX IT! would like to thank all the volunteers who tested and
                helped shape the smallest details of the game. Special thanks to
                the MA in Sports Ethics and Integrity students and alumni.
              </p>
            </div>
          )}
        </section>
      </main>
      <footer id="buy-fix-it">
        <List containerClass="footer-item-list" items={footerItems} />
      </footer>
    </div>
  );
}

export default App;
